export function useFormatPrice() {
  const { countryAndLanguage } = useRouteHelper()
  const { getCurrencySymbol } = useCurrency()

  const formatPriceByLocale = (currency: string, amountFloat: number) => {
    const currencySymbol =
      getCurrencySymbol(currency.toUpperCase() as Uppercase<string>) ?? ''
    return formatPrice(currencySymbol, amountFloat, countryAndLanguage)
  }

  const maybeFormatPriceByLocale = (
    currency?: string | null,
    amountFloat?: number | null
  ) => {
    const currencySymbol =
      currency &&
      getCurrencySymbol((currency.toUpperCase() as Uppercase<string>) ?? '')
    return maybeFormatPrice(currencySymbol, amountFloat, countryAndLanguage)
  }

  return {
    formatPriceByLocale,
    maybeFormatPriceByLocale,
  }
}
