const currencySymbol = {
  EUR: '€',
  USD: '$',
  JPY: '¥',
} as Record<string, string>

export const formatPrice = (
  currency: string,
  amountFloat: number,
  countryCode: string
) => {
  const localeCode = countryCode?.replace(/-\w+$/, m => m?.toUpperCase())

  const fractionDigits = Number.isInteger(amountFloat) ? 0 : 2
  return `${currencySymbol[currency] ?? currency} ${amountFloat?.toLocaleString(
    localeCode,
    {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    }
  )}`
}

export const maybeFormatPrice = (
  currency?: string | null,
  amountFloat?: number | null,
  countryCode?: string | null
) =>
  typeof currency === 'string' &&
  typeof amountFloat === 'number' &&
  typeof countryCode === 'string'
    ? formatPrice(currency, amountFloat, countryCode)
    : null

export const stringPriceToNumber = (price: string) => {
  const cleanedPrice = price?.replace(/[^\d,]/g, '')
  return Math?.trunc(parseFloat(cleanedPrice))
}

export const removeCurrencyFromNumber = (price: string) => {
  const intermediatePrice = price?.replace(/^./, '')
  const formattedPrice = parseInt(intermediatePrice?.replace(/[,.]/g, ''))
  return formattedPrice
}
